/** @jsx jsx */
import {jsx} from 'theme-ui';
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
// import './all.sass'
import "bulma/css/bulma.css";
import { Global } from '@emotion/core'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/jumbotron-landing.jpg" />
      </Helmet>
      <Global
    styles={theme => ({
      '*': {
        boxSizing: 'border-box',
      },
        body: {
          fontFamily: theme.fonts.body,
          fontSize: 20,
          margin: 0
        },
      '.content .taglist': {
        listStyle: 'none',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: '1.5rem',
        marginTop: '1.5rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        alignItems: 'center',
      },
      '.content .taglist li': {
        padding: '0 2rem 1rem 0',
        marginBottom: '1.5rem',
        marginTop: 0,
      },
      '.full-width-image-container': {
        width: '100vw',
        height: '400px',
        position: 'relative',
        left: '50%',
        right: '50%',
        margin: '2em -50vw',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '.full-width-image': {
        width: '100vw',
        height: '400px',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '.btn': {
        display: 'inline-block',
        padding: '12px 16px 10px',
        fontSize: '18px',
        fontSize: '1rem',
        lineHeight: '1.25',
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        textDecoration: 'none',
        fontWeight: 700,
        color: '#0058cc',
        textAlign: 'center',
        boxShadow: 'inset 0 0 0 2px #82b4b9',
        transition: 'all 0.15s ease',
      },

    '.margin-top-0': {
      marginTop: '0 !important'
    },

    '.navbar-item .icon': {
      color: '#82b4b9'
    },

    '.icon svg': {
      width: '1.5rem',
      height: '1.5rem',
      fill: 'currentColor'
    },

    '.navbar-brand .navbar-item.logo': {
      padding: '0 1rem'
    },
    'footer.footer':  {
      padding: '3rem 0rem 0rem',
      backgroundColor: 'transparent'
    },

    '.menu-label': {
      fontSize: '1em !important',
      textAlign: 'left'
    },

    '.menu-list': {
      listStyle: 'none !important',
      textAlign: 'left'
    },

    '.social': {
      padding: '2em'
    },

    '.social a': {
      padding: '0.5em 0.5em 0.3em 0.5em',
      borderRadius: '1em',
      backgroundColor: '#ffffff',
      margin: '0.5em',
      width: '1em',
      height: '1em',
      verticalAlign: 'middle',
      display: 'inline'
    },
    '.blog-list-item.is-featured': {
        backgroundColor: '#d6400033'
    },

    '.blog-list-item header': {
      display: 'flex',
      marginBottom: '1em'
    },

    '.blog-list-item .featured-thumbnail' : {
      flexBasis: '35%',
      margin: '0 1.5em 0 0'
    },

    '.shine': {
      height: '20px',
      width: '100%',
      backgroundImage: 'radial-gradient(farthest-side at 50% -50%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
      position: 'relative'
    },

  '.shine::before': {
    height: '1px',
    position: 'absolute',
    top: '-1px',
    left: 0,
    right: 0,
    backgroundImage: 'linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0))'
  }

    })}
  />
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
